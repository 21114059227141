import * as React from 'react'
import * as qs from 'qs'
import {Helmet} from 'react-helmet'

import DelayedFlights from '../components/DelayedFlights/DelayedFlights'

interface Props {
    location: Location
}

export default (props: Props) => {
    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true })
    return <>
        <DelayedFlights flightId={query.flightId ? Number(query.flightId) : undefined} />
        <Helmet>
            <meta name="robots" content="noindex"/>
        </Helmet>
    </>
}
