import * as _ from 'lodash'
import * as fp from 'lodash/fp'
import * as React from 'react'
import {
    Container, Row, Col, Table, Modal, ModalHeader, ModalBody, Alert, Button, ButtonGroup
} from 'reactstrap'
import { Spinner } from 'react-activity'
import * as Moment from 'moment'
import { navigate } from 'gatsby-link'
import * as store from 'store'

import './DelayedFlights.scss'


const FAVORITES_STORE_KEY = 'flight-monitor.favorites'
const SHOW_FAVORITES_STORE_KEY = 'flight-monitor.show-favorites'

function getFavorites() {
    return store.get(FAVORITES_STORE_KEY) || []
}

function toggleFavorite (iata) {
    store.set(FAVORITES_STORE_KEY, _.xor(getFavorites(), [iata]))
}

function isFavorite (iata) {
    console.log("isFavorite", iata, _.includes(store.get(FAVORITES_STORE_KEY), iata))
    return _.includes(store.get(FAVORITES_STORE_KEY), iata)
}

function getShowFavorites () {
    return store.get(SHOW_FAVORITES_STORE_KEY)
}

function setShowFavorites (show: boolean) {
    store.set(SHOW_FAVORITES_STORE_KEY, show)
}


const HIGHLIGHT_THRESHOLD = 180

enum FlightDirection {
    ARRIVAL = 'arr',
    DEPARTURE = 'dep',
}

type Flight = {
    id: number
    direction: FlightDirection
    airport_iata: string
    departure_airport: string
    departure_airport_iata: string
    arrival_airport: string
    arrival_airport_iata: string
    airline: string
    airline_iata: string
    flight_number: number
    scheduled_time: string
    scheduled_time_local: string
    estimated_time: string | null
    estimated_time_local: string | null
    delay: number
    terminal: string | null
    gate: string | null
    schedule_type: string | null
}

type HeaderProps = {}

const Header = ({showFavorites, setShowFavorites}) => {
    return <div className="mt-5 mb-5">
        <Row>
            <Col sm={9}>
                <h1>Delayed Flights</h1>
            </Col>

            <Col sm={3} className="text-right">
                <ButtonGroup>
                    <Button
                        active={!showFavorites}
                        onClick={() => setShowFavorites(false)}
                        color='primary'
                    >
                        All
                    </Button>

                    <Button
                        active={showFavorites}
                        onClick={() => setShowFavorites(true)}
                        color='primary'
                    >
                        Favorite
                    </Button>
                </ButtonGroup>
            </Col>
        </Row>
    </div>
}

type FlightRowProps = {
    flight: Flight
    openFlight: (flightId: number) => void
}

const FlightRow = (props: FlightRowProps) => {
    const { flight } = props

    const isArrival = flight.direction === FlightDirection.ARRIVAL
    const isHighlighted = flight.delay >= HIGHLIGHT_THRESHOLD

    return (
        <tr
            className={['FlightRow', isHighlighted ? 'isHighlighted' : ''].join(' ')}
            onClick={() => {
                props.openFlight(flight.id)
            }}
        >
            <td>
                <abbr title={flight.airline}>{flight.airline_iata}</abbr>
            </td>
            <td>{flight.flight_number}</td>
            <td>
                {isArrival ? (
                    <>
                        ←
                        <abbr title={flight.departure_airport}>
                            {flight.departure_airport_iata}
                        </abbr>
                    </>
                ) : (
                    <>
                        →
                        <abbr title={flight.arrival_airport}>
                            {flight.arrival_airport_iata}
                        </abbr>
                    </>
                )}
            </td>
            <td>{flight.estimated_time_local ? Moment.parseZone(flight.estimated_time_local).format('HH:mm') : '-'}</td>
            <td>{flight.terminal || '-'} / {flight.gate || '-'}</td>
            <td>{flight.delay}m</td>
        </tr>
    )
}

type FlightTableProps = {
    airportIata: string
    flights: Flight[]
    toggleFavorite: (string) => void
    openFlight: (flightId: number) => void
}

const FlightTable = (props: FlightTableProps) => {
    return <div className="FlightTable">
        <h3>
            {props.airportIata}
            {" "}
            <Button
                className={`fav ${isFavorite(props.airportIata) ? 'selected' : ''}`}
                size="sm"
                color="info"
                outline
                onClick={() => props.toggleFavorite(props.airportIata)}
            >★</Button>
        </h3>
        <Table size="sm" borderless={false} striped hover>
            <tbody>
                {props.flights.map(flight => (
                    <FlightRow
                        key={flight.id}
                        flight={flight}
                        openFlight={props.openFlight}
                    />
                ))}
            </tbody>
        </Table>
    </div>
}

type FlightModalProps = {
    flight?: Flight
    closeFlight: () => void
}

const FlightModal = (props: FlightModalProps) => {
    const { flight } = props

    if (!flight) {
        return null
    }

    return (
        <Modal
            isOpen={!!flight}
            centered
            keyboard
            toggle={() => {
                props.closeFlight()
            }}
        >
            <ModalHeader
                toggle={() => {
                    props.closeFlight()
                }}
            >
                {flight.airline} {flight.airline_iata} {flight.flight_number}
            </ModalHeader>
            <ModalBody>
                <h4 className="pb-2">
                    {flight.departure_airport} ({flight.departure_airport_iata}) {" -> "} {flight.arrival_airport} ({flight.arrival_airport_iata})
                </h4>

                <Table size="sm">
                    <tbody>
                        <tr>
                            <td scope="col">Scheduled</td>
                            <td>{Moment.parseZone(flight.scheduled_time_local).format('YYYY-MM-DD HH:mm')}</td>
                        </tr>
                        <tr>
                            <td scope="col">Estimated</td>
                            <td>{flight.estimated_time_local ? Moment.parseZone(flight.estimated_time_local).format('YYYY-MM-DD HH:mm') : '-'}</td>
                        </tr>
                        <tr>
                            <td scope="col">Terminal / gate</td>
                            <td>{flight.terminal || '-'} / {flight.gate || '-'}</td>
                        </tr>
                        <tr>
                            <td scope="col">Delay</td>
                            <td>{flight.delay}m</td>
                        </tr>
                        <tr>
                            <td scope="col">Type</td>
                            <td>{flight.schedule_type || '-'}</td>
                        </tr>
                    </tbody>
                </Table>
            </ModalBody>
        </Modal>
    )
}

type Props = {
    flightId?: number
}

type State = {
    flight?: Flight
    isFlightInactive: boolean
    flights: Flight[]
    isFlightsLoaded: boolean
    serial: number
    showFavorites: boolean
}

export default class DelayedFlights extends React.Component<Props, State> {
    state = {
        flight: undefined,
        isFlightInactive: false,
        flights: [],
        isFlightsLoaded: false,
        serial: 1,
        showFavorites: getShowFavorites()
    }

    static getDerivedStateFromProps(props, state) {
        const flight = props.flightId && _.find(state.flights, { id: props.flightId })

        return {
            flight,
            isFlightInactive: state.isFlightsLoaded && props.flightId && !flight,
        }
    }

    async componentDidMount() {
        const response = await fetch('/api/delayed-flights')
        const data = await response.json()

        this.setState({
            flights: data,
            isFlightsLoaded: true,
        })
    }

    getFlightsByAirport() {
        return fp.pipe(
            fp.groupBy('airport_iata'),
            fp.mapValues(fp.sortBy(flight => (flight as any).estimated_time)),
            fp.toPairs,
            fp.sortBy(([airport_iata]) => airport_iata),
        )(this.state.flights)
    }


    toggleFavorite = (iata) => {
        toggleFavorite(iata)
        this.setState({serial: this.state.serial + 1})
    }

    setShowFavorites = (show) => {
        setShowFavorites(show)
        this.setState({showFavorites: show})
    }

    render() {
        let flightsByAirport = this.getFlightsByAirport()

        if (this.state.showFavorites) {
            flightsByAirport = flightsByAirport.filter(i => isFavorite(i[0]))
        }

        return <Container fluid className="mb-5">
            <Container>
                <Header
                    showFavorites={this.state.showFavorites}
                    setShowFavorites={this.setShowFavorites}
                />
            </Container>

            {this.state.isFlightInactive && (
                // TODO: Load flights from API.
                <Alert color="danger" className="mb-5">
                    Flight is not active anymore!
                </Alert>
            )}

            {!this.state.isFlightsLoaded && (
                <div className="d-flex justify-content-center mt-5 mb-5">
                    <Spinner />
                </div>
            )}

            <Container fluid>
                {_.map(_.chunk(flightsByAirport, 3), (rows, columnIndex) => {
                    return (
                        <Row key={columnIndex}>
                            {_.map(rows, ([airportIata, flights]) => (
                                <Col key={airportIata} md="6" lg="4" xl="4" className="mb-3">
                                    <FlightTable
                                        airportIata={airportIata}
                                        flights={flights}
                                        toggleFavorite={this.toggleFavorite}
                                        openFlight={(flightId: number) => {
                                            navigate(`/delayed-flights?flightId=${flightId}`)
                                        }}
                                    />
                                </Col>
                            ))}
                        </Row>
                    )
                })}
            </Container>

            <FlightModal
                flight={this.state.flight}
                closeFlight={() => {
                    navigate('/delayed-flights')
                }}
            />
        </Container>
    }
}
